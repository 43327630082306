import React from 'react';

import Title from 'components/title';
import Text from 'components/utilities/text';

import { Container } from './form.css';

const Form = ({
  name = "Contact Form",
  subject = "", // optional subject of the notification email
  title,
  content
}) => {
  return (
    <Container
      method="post"
      netlify-honeypot="bot-field"
      data-netlify="true"
      action="/thanks"
      name="contact"
      className="Form"
    >
      <Title as="h2">{title}</Title>
      <Text as="p" className="mb-5">{content}</Text>
      <label className="mb-4">
        <input
          className=""
          type="text"
          placeholder="Name"
          name="name"
          required
        />
      </label>
      <label className="mb-4">
        <input
          className=""
          type="email"
          placeholder="Email"
          name="email"
          required
        />
      </label>
      <label className="mb-4">
        <select
          className="select-css"
          name="type"
          defaultValue="Type of Enquiry"
          required
        >
          <option disabled hidden>
          Type of Enquiry
        </option>
          <option>Web Development</option>
          <option>Mobile Development</option>
          <option>CMS Website</option>
          <option>Bug Found</option>
          <option>Just want to say hello</option>
        </select>
      </label>

      <label className="mb-4">
        <input
          className=""
          type="text"
          placeholder="$ Budget"
          name="budget"
          required
        />
      </label>

      <label className="mb-4">
        <textarea
          className=""
          placeholder="Message"
          name="message"
          rows="10"
          required
        />
      </label>
      <input type="text" name="bot-field" style={{ display: 'none' }} />
      {!!subject && <input type="hidden" name="subject" value={subject} />}
      <input type="hidden" name="form-name" value={name} />
      <button className="btn btn-lg btn-dark lead py-4" type="submit"><span className="h3">Send</span></button>
      <div className="d-flex flex-column align-items-center justify-content-center text-center mt-5">
        <span className="h2">
          None of the options above seem suitable?
          <br />
          Send general inquiries to&nbsp;
          <a href="mailto:oscar@hustlebox.ca" target="_blank" rel="noreferrer">
            oscar@hustlebox.ca
          </a>
        </span>
      </div>
    </Container>
  )
}

export default Form;