import styled from 'styled-components';

export const Container = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  padding: 50px 70px;
  background: #ffffff;
  box-shadow: 0px 15px 70px -8px rgba(0,0,0,.11);
  @media (max-width: 768px) {
    padding: 0;
    background: #F8F9FB;
    box-shadow: none;
  }

  input, textarea {
    font-family: inherit;
    flex-grow: 1;
    box-sizing: border-box;
    display: block;
    margin: 0;
    padding: 16px 10px;
    width: 100%;
    background: #FFFFFF;
    border: 3px solid #E0E4E7;
    border-radius: 2px;
    font-size: 16px;
    line-height: 1.3;
    color: #333;
    transition: border-color 0.2s;
    resize: none;
    transition: 0.3s ease all;
  }
  input::placeholder,
  textarea::placeholder {
    color: #bfbfbf;
  }
  .select-css {
    display: block;
    margin: 0;
    padding: 12px 24px 12px 16px;
    height: 62px;
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
      linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 2px;
    border: 3px solid #E0E4E7;
    font-size: 16px;
    font-family: sans-serif;
    font-weight: 400;
    line-height: 1.3;
    color: #333;
    box-sizing: border-box;

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }
  .select-css::-ms-expand {
    display: none;
  }
  .select-css:hover {
    border-color: #888;
  }
  .select-css:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px #333;
    /* box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7); */
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }
  .select-css option {
    font-weight: normal;
  }
`;
